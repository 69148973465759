<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Simple pricing</h1>
      <p>
        We like to keep things simple. At {{tenant.name}} you pay a small fee for each user you invite into your fitness event. 
        The exact fee depends on the size of your event and the features you need. Pricing in EUR and GBP is available on request.
      </p>
    </div>
    <v-alert type="info">
      We'll be introducing new plans early September. Lock in the current pricing by requesting a quote or by pre-purchasing user licenses now.
    </v-alert>

      <v-row class="mb-8 mx-0 pricing-details">
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="white lighten-2" class="pa-4">
            <h3 class="text-center">Free</h3>
            <p>Try out for yourself and up to 5 team members.</p>
            <v-btn outlined to="/eventmanager/create">Select FREE</v-btn>
            <hr/>
            <span class="large">FREE</span><span class="small">up to 5 users</span>
            <hr/>
            <ul class="check-list">
              <li>Up to 5 users</li>
              <li>All features from Basic plan</li>
              <li><router-link to="/microsoft-teams">Microsoft Teams Integration</router-link></li>
              <li><router-link to="/slack">Slack Integration</router-link></li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="white lighten-1" class="pa-4">
            <h3 class="text-center">Basic</h3>
            <p>All the basics you need to start your first corporate challenge.</p>
            <v-btn outlined color="green" to="/eventmanager/create?license=BASIC">Select BASIC</v-btn>
            <hr/>
            <span class="large">$ 1</span><span class="small">per user <br/> per event</span>
            <hr/>
            <ul class="check-list">
              <li>Up to 250 users</li>
              <li>Auto-sync with any of our 9+ supported fitness device</li>
              <li>iOS and Android app</li>
              <li>Basic challenge types (including step and distance challenges)</li>
              <li>Social and real-time Leaderboards (with liking, commenting, and selfies)</li>
              <li>Virtual course maps</li>
              <li>Customize the branding of each challenge</li>
              <li>Self-service dashboard to view and manage all challenges</li>
            </ul>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="5" outlined color="white lighten-0" class="pa-4 popular">
            <h3 class="text-center">Pro <v-chip small outlined color="black">Most Popular</v-chip></h3>
            <p>Advanced features to host engaging challenges.</p>
            <v-btn color="green" to="/eventmanager/create?license=PRO">Select PRO</v-btn>
            <hr/>
            <span class="large">$ 1.5</span><span class="small">per user<br/>per event</span>
            <hr/>
            <ul class="check-list">
              <li class="font-weight-bold">All features from Basic plan</li>
              <li>Unlimited users</li>
              <li><router-link to="/microsoft-teams">Microsoft Teams Integration</router-link></li>
              <li><router-link to="/slack">Slack Integration</router-link></li>
              <li>Team leaderboards</li>
              <li>Advanced challenge types (training plans, streak challenges, etc)</li>
              <li>Import Excel files with participant details</li>
              <li>Export Excel Reports</li>
              <li>Permissions and integrations</li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="grey darken-3 white--text" class="pa-4">
            <h3 class="text-center">Enterprise</h3>
            <p>Custom plan with advanced branding and integrations.</p>
            <v-btn text color="green" to="/contact">Contact Sales</v-btn>
            <hr/>
            <span class="large">Contact Us</span>
            <hr/>
            <ul class="check-list">
              <li class="font-weight-bold">All features from Pro plan</li>
              <li>Unlimited users</li>
              <li>SSO (Single Sign-On integrations)</li>
              <li>Custom domain</li>
              <li>API integrations</li>
              <li>Full control over design and email templates</li>
            </ul>
          </v-sheet>
        </v-col>
      </v-row>    

      <h3>Add-Ons</h3>
      <v-sheet elevation="3" color="grey lighten-4" class="pa-4 pricing-details">
        <h3>Full-Service Package</h3>
        <p>Add the Full-Service Package to your event to have us do the setup and support for your event. The Full-Service Package is available for PRO plans only.</p>
        <hr/>
        <span class="large">$ 250</span><span class="small">one-time<br/>per event</span>
      </v-sheet>


     <div id="page-customers" class="mt-8 blob-bg white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>
          <v-col class="text-center" cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0">
              Over <strong>1,087 events</strong> have been created already. Join today!
            </p>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>

          </v-col>
        </v-row>
      </div>
    </div>
    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="4" md="2">
          <img src="https://sodisp.imgix.net/content/quote-drift-kelly.jpg?w=200&h=200&fit=facearea&faceindex=1&facepad=4&mask=ellipse&mask-bg=f5f5f5" style="max-width:100%;" />
        </v-col>
        <v-col cols="8" md="10">
          <blockquote>
            "At Drift we had a goal of 10% participation in our first challenge and had over 20% of our employees signed up. [...] This is by far the smoothest platform I have used to date and our Drifters are already asking when the next challenge will take place."
          </blockquote>
          <p class="mt-8">
            Kelly Reardon<br/>
            <i>Drift Experience Manager @ <a href="https://drift.com" target="_blank">Drift</a></i>
          </p>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col cols="4" md="2">
          <img src="https://sodisp-ugc.imgix.net/e_ecratchit-step-challenge-2022_logo_20220505204729?w=200&h=200&mask=ellipse&mask-bg=f5f5f5" style="max-width:100%;" />
        </v-col>
        <v-col cols="8" md="10">
          <blockquote>
            "We are loving our Step Challenge! It is a huge hit!"
          </blockquote>
          <p class="mt-8">
            Gail Miller<br/>
            <i>Drift Experience Manager @ <a href="https://ecratchit.com" target="_blank">eCratchit</a></i>
          </p>
        </v-col>
      </v-row>
    </v-container>
    </div>

    <div class="faqs mt-8">
      <h1 class="headline">Frequently Asked Questions</h1>

      <h3 class="subtitle">Can I try before I buy?</h3>
      <p>Sure! Our Free plan contains the basic features to get you started and try out with 5 team members. Once you are convinced you can easily upgrade to a paid plan.</p>

      <h3 class="subtitle">Can I add more user credits later on?</h3>
      <p>Of course. You can always add more user credits when you reach your maximum.</p>

      <h3 class="subtitle">What is an 'event'?</h3>
      <p>An event is a single event or challenge which may last up to 3 months. A single event may have one or more leaderboards active at the same time. Please contact us if you have an event which lasts longer than 3 months to discuss possibilities.</p>

      <h3 class="subtitle">Is pricing available in EUR or GBP?</h3>
      <p>Yes, please <router-link to="contact">contact us</router-link> for a quote in EUR or GBP.</p>

      <h3 class="subtitle">What payment methods are available?</h3>
      <p>By default you can pay using credit card. <router-link to="contact">Reach out to us</router-link> to enable ACH, IBAN or wire transfer payments.</p>

      <h3 class="subtitle">How can I cancel my payment?</h3>
      <p>By default you purchase a one-time challenge which may run for up to 3 months. As there is no auto-renewal, there is no need to cancel your payment.</p>

      <h3 class="subtitle">Can I get a refund?</h3>
      <p>If you're not 100% convinced with out platform you can always get a full refund. The only condition is that your event hasn't started yet.</p>

      <h3 class="subtitle">Do you offer subscription model for a monthly or yearly fitness program?</h3>
      <p>Yes, we do! Please reach out to us to explore how this works.</p>

      <h3 class="subtitle">Any other question?</h3>
      <p>We'd <router-link to="contact">love to hear from you</router-link>.</p>
    </div>

    <v-container class="text-center">
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/content/cycling-mountains.jpg?w=1080&amp;h=500&amp;fit=fillmax" alt="Create engaging and connected corporate fitness events and challenges." /> 
    </v-container>

    <div class="secondary white--text py-6 py-md-12 mt-4">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Schedule a call to discuss pricing for <u>your</u> corporate fitness event</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/request-demo">Request Demo</v-btn>
              <v-btn class="mb-4 mr-4" color="white" outlined to="/features">Explore features</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>


  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .pricing-details {
    .v-sheet { border-radius: 10px !important; text-align: center;; }
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .small { font-size: 75%; display: inline-block; text-align: left;}
    .large { font-size: 200%;}
    .large + .small { margin-left: 5px; }
    .popular { border: solid 5px #00B46E !important; }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
  
  .faqs {
    h3 { margin-top: 1.5em; }
  }
</style>